<template>
  <div style="display: flex;justify-content: space-between;align-items: flex-start;flex-wrap: wrap">
    <div style="width: 100%;display: flex">
      <div class="block">
        <span class="demonstration"></span>
        <el-date-picker size="mini"
                        v-model="startDate"
                        align="right"
                        type="date"
                        placeholder="시작일 선택"
                        :picker-options="pickerOptions"
                        @change="setStartDate">
        </el-date-picker>
      </div>
      <div style="padding-left: 2px;padding-right: 2px">~</div>
      <div class="block">
        <span class="demonstration"></span>
        <el-date-picker size="mini"
                        v-model="endDate"
                        align="right"
                        type="date"
                        placeholder="마감일 선택"
                        :picker-options="pickerOptions"
                        @change="setEndDate">
        </el-date-picker>
      </div>
      <el-button type="primary" @click="search" size="mini" style="margin-left: 10px">검색</el-button>
    </div>
    <div style="width:20%;min-height: 300px">
      <h5 style="background-color: #b3d8ff;line-height: 30px;padding: 0 5px">접속도메인 기록</h5>
      <el-table
          :data="domainList"
          style="width: 100%"
          max-height="730"
          border>
        <el-table-column
            label="도메인"
            width="80">
          <template slot-scope="scope">
            {{ scope.row.domain }}
          </template>
        </el-table-column>
        <el-table-column
            label="접속회수"
            width="80">
          <template slot-scope="scope">
            {{ scope.row.c }}
          </template>
        </el-table-column>
      </el-table>

    </div>
    <div style="width: 80%;min-height: 300px">

      <h5 style="background-color: #b3d8ff;line-height: 30px;padding: 0 5px">접속기록</h5>
      <el-table
          :data="logList"
          style="width: 100%"
          max-height="730"
          border>
        <el-table-column
            label="날짜"
            width="90">
          <template slot-scope="scope">
            {{ scope.row.createTime|datef('MM-DD HH:mm') }}
          </template>
        </el-table-column>
        <el-table-column
            label="IP"
            width="200">
          <template slot-scope="scope">
            {{ scope.row.loginIp }}
          </template>
        </el-table-column>

        <el-table-column
            label="국가"
            width="100">
          <template slot-scope="scope">
            <img src="../../assets/images/ipcountry/KR.gif" alt="" v-if="scope.row.ipcountry == 'KR'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            <img src="../../assets/images/ipcountry/CN.gif" alt="" v-if="scope.row.ipcountry == 'CN'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            <img src="../../assets/images/ipcountry/CN.gif" alt="" v-if="scope.row.ipcountry == 'HK'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            <img src="../../assets/images/ipcountry/JP.gif" alt="" v-if="scope.row.ipcountry == 'JP'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            <img src="../../assets/images/ipcountry/KH.gif" alt="" v-if="scope.row.ipcountry == 'KH'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            <img src="../../assets/images/ipcountry/PH.gif" alt="" v-if="scope.row.ipcountry == 'PH'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            <img src="../../assets/images/ipcountry/SG.gif" alt="" v-if="scope.row.ipcountry == 'SG'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            <img src="../../assets/images/ipcountry/TH.gif" alt="" v-if="scope.row.ipcountry == 'TH'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            <img src="../../assets/images/ipcountry/US.gif" alt="" v-if="scope.row.ipcountry == 'US'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            <img src="../../assets/images/ipcountry/VN.gif" alt="" v-if="scope.row.ipcountry == 'VN'"
                 style="height: 20px;vertical-align: center;margin-right: 5px">
            {{ scope.row.ipcountry }}
          </template>
        </el-table-column>
        <el-table-column
            label="지역"
            width="100">
          <template slot-scope="scope">
            {{ scope.row.ipregion }}
          </template>
        </el-table-column>
        <el-table-column
            label="VPN타입"
            width="150">
          <template slot-scope="scope">
            {{ scope.row.networktype }}
          </template>
        </el-table-column>

        <el-table-column
            label="-"
            width="320">
          <template slot-scope="scope">
            {{ scope.row.description }}
          </template>
        </el-table-column>

        <el-table-column
            label="-"
            width="820">
          <template slot-scope="scope">
            {{ scope.row.browser }}
          </template>
        </el-table-column>

      </el-table>
      <div class="pagePanel">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[5,15,50, 100, 150, 200]"
            :page-size="pageSize"
            layout="total,  prev, pager, next"
            :total="pageTotal">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
import managerConst from "../../common/administrator/managerConst";
import {getApplyList} from "../../network/manager/applyRequest";
import {getLoginLogList} from "../../network/manager/logLoginRequest";

export default {
  name: "UserInfoLoginLogComp",
  data() {
    return {
      startDate: '',
      endDate: '',
      logList: [],
      domainList: [],
      managerConst: managerConst,
      pageNum: 1,
      pageSize: 15,
      pageTotal: 0,
      pickerOptions: {
        shortcuts: [{
          text: '오늘',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '어제',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '일주일전',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }, {
          text: '15일전',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 14);
            picker.$emit('pick', date);
          }
        }, {
          text: '30일전',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', date);
          }
        }]
      },

    }
  },
  props: {
    uid: {
      type: Number,
      default() {
        return 0;
      }
    },

  },
  methods: {
    getLoginLogList() {
      let record = {}
      record.startDate = this.startDate
      record.endDate = this.endDate
      record.loginId = this.uid
      record.loginType = managerConst.LogLogin.LOG_LOGIN_TYPE_USER
      getLoginLogList(record, this.pageNum, this.pageSize).then(res => {
        this.logList = res.data.data.result
        this.domainList = res.data.data.domainMap
        this.pageTotal = res.data.total
      })
    },

    handleSizeChange(val) {
      this.pageNum = 1;
      this.pageSize = val;
      this.getLoginLogList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getLoginLogList();
    },

    setStartDate() {
      this.startDate = this.$moment(this.startDate).format('yyyy-MM-DD');
    },
    setEndDate() {
      this.endDate = this.$moment(this.endDate).format('yyyy-MM-DD')
    },
    search() {
      this.getLoginLogList();
    },
  },
  created() {

  },
  watch: {
    uid: {
      handler(newVal) {
        if (newVal != null && newVal != 0) {
          this.startDate = this.$moment().subtract(1, 'days').format('yyyy-MM-DD')
          this.endDate = this.$moment().format('yyyy-MM-DD')
          this.getLoginLogList()
        }
      },
      immediate: true,
      deep: false
    },
  }
}
</script>

<style scoped>

</style>