<template>
   <div>
       <div style="width: 100%;display: flex;margin-bottom: 5px">
           <div class="block">
               <span class="demonstration"></span>
               <el-date-picker size="mini"
                               v-model="startDate"
                               align="right"
                               type="date"
                               placeholder="시작일 선택"
                               :picker-options="pickerOptions"
                               @change="setStartDate">
               </el-date-picker>
           </div>
           <div style="padding-left: 2px;padding-right: 2px">~</div>
           <div class="block">
               <span class="demonstration"></span>
               <el-date-picker size="mini"
                               v-model="endDate"
                               align="right"
                               type="date"
                               placeholder="마감일 선택"
                               :picker-options="pickerOptions"
                               @change="setEndDate">
               </el-date-picker>
           </div>
           <el-button type="primary" @click="selectCashOrPoint" size="mini" style="margin-left: 10px">검색</el-button>
           <el-select v-model="log.cashOrPoint" @change="getLogList" size="mini" placeholder="머니/포인트"
                      style="width:110px;margin-left: 5px">
               <el-option key="1" label="전체" :value="null">전체</el-option>
               <el-option key="2" label="머니내역" :value="managerConst.CashPointLog.LOG_CASHPOINT_CASH">머니내역</el-option>
               <el-option key="3" label="포인트내역" :value="managerConst.CashPointLog.LOG_CASHPOINT_POINT">포인트내역</el-option>
           </el-select>
           <el-select v-model="log.display" @change="getLogList" size="mini" placeholder="상태"
                      style="width:110px;margin-left: 5px">
               <el-option label="전체" :value="null">전체</el-option>
               <el-option label="정상내역" :value="managerConst.ENABLE">정상내역</el-option>
               <el-option label="숨김내역" :value="managerConst.DISABLE">숨김내역</el-option>
           </el-select>
           <el-select v-model="log.type" @change="managerAddCashLogSearch" size="mini" placeholder="수동내역검색"
                      style="width:130px;margin-left: 5px">
               <el-option label="전체" :value="null">전체</el-option>
               <el-option label="수동머니추가내역" :value="managerConst.CashPointLog.LOG_CASH_TYPE_MANAGER_ADDCASH">수동머니추가내역(+)</el-option>
               <el-option label="수동머니차감내역" :value="managerConst.CashPointLog.LOG_CASH_TYPE_MANAGER_MINUSCASH">수동머니차감내역</el-option>
               <el-option label="수동포인트추가내역" :value="managerConst.CashPointLog.LOG_POINT_TYPE_MANAGER_ADDPOINT">수동포인트추가내역(+)</el-option>
               <el-option label="수동포인트차감내역" :value="managerConst.CashPointLog.LOG_POINT_TYPE_MANAGER_MINUSPOINT">수동포인트차감내역</el-option>
               <el-option label="첫충보너스" :value="managerConst.CashPointLog.LOG_CASHPOINT_TYPE_PLUS_CASH_RECHARGE_BONUS">첫충보너스</el-option>
               <el-option label="매충보너스" :value="managerConst.CashPointLog.LOG_CASHPOINT_TYPE_PLUS_CASH_DAY_RECHARGE_BONUS">매충보너스</el-option>
           </el-select>
       </div>
       <div>
           <el-table
                   :data="logList"
                   style="width: 100%"
                   max-height="730"
                   border>
               <el-table-column
                       label="날짜"
                       width="130">
                   <template slot-scope="scope">
                       {{scope.row.createTime|datef('MM-DD HH:mm:ss')}}
                   </template>
               </el-table-column>

               <el-table-column
                       label="머니 or 포인트"
                       width="110">
                   <template slot-scope="scope">
                       <span v-if="scope.row.cashOrPoint == managerConst.CashPointLog.LOG_CASHPOINT_CASH">머니</span>
                       <span v-if="scope.row.cashOrPoint == managerConst.CashPointLog.LOG_CASHPOINT_POINT">Point</span>
                   </template>
               </el-table-column>
               <el-table-column
                       label="전금액"
                       width="100">
                   <template slot-scope="scope">
                       {{scope.row.oldValue|comma}}
                   </template>
               </el-table-column>
               <el-table-column
                       label="수량"
                       width="100">
                   <template slot-scope="scope">
                       <span class="text-blue" v-if="scope.row.plusOrMinus == managerConst.CashPointLog.LOG_CASHPOINT_PLUS">+{{scope.row.amount | comma}}</span>
                       <span class="text-red" v-if="scope.row.plusOrMinus == managerConst.CashPointLog.LOG_CASHPOINT_MINUS">-{{scope.row.amount | comma}}</span>
                   </template>
               </el-table-column>
               <el-table-column
                       label="현재"
                       width="100">
                   <template slot-scope="scope">
                       {{scope.row.currentValue|comma}}
                   </template>
               </el-table-column>

               <el-table-column
                       label="내용"
                       width="500">
                   <template slot-scope="scope">
                       <span class="bg-skyblue" v-if="
                        scope.row.type == managerConst.CashPointLog.LOG_CASH_TYPE_MANAGER_ADDCASH
                       ||scope.row.type == managerConst.CashPointLog.LOG_CASH_TYPE_MANAGER_MINUSCASH
                       ||scope.row.type == managerConst.CashPointLog.LOG_POINT_TYPE_MANAGER_ADDPOINT
                       ||scope.row.type == managerConst.CashPointLog.LOG_POINT_TYPE_MANAGER_MINUSPOINT
                       ||scope.row.type == managerConst.CashPointLog.LOG_CASHPOINT_USER_PLUS_MOVE_2_USER
                       ||scope.row.type == managerConst.CashPointLog.LOG_CASHPOINT_PARTNER_PLUS_MOVE_2_USER
                       ||scope.row.type == managerConst.CashPointLog.LOG_CASHPOINT_PARTNER_PLUS_MOVE_2_DAILI
                       ||scope.row.type == managerConst.CashPointLog.LOG_CASHPOINT_USER_PLUS_MOVE_2_DAILI
                        ||scope.row.type == managerConst.CashPointLog.LOG_OPERATE_COUPON"> {{scope.row.content}}</span>

                     <span class="bg-red" v-else-if="
                       scope.row.type == managerConst.CashPointLog.LOG_CASHPOINT_USER_MINUS_MOVE_2_USER
                      ||scope.row.type == managerConst.CashPointLog.LOG_CASHPOINT_USER_MINUS_MOVE_2_DAILI
                      ||scope.row.type == managerConst.CashPointLog.LOG_CASHPOINT_PARTNER_MINUS_MOVE_2_USER
                      ||scope.row.type == managerConst.CashPointLog.LOG_CASHPOINT_PARTNER_MINUS_MOVE_2_DAILI"> {{scope.row.content}}</span>

                     <span class="" v-else> {{scope.row.content}}</span>
                   </template>
               </el-table-column>
               <el-table-column
                       label="베팅 or 입출금 번호"
                       width="120">
                   <template slot-scope="scope">
                       <span v-if="scope.row.applyId != null"> {{scope.row.applyId}}</span>
                       <span v-if="scope.row.betInfoId != null">
                           <span v-if="scope.row.protoOrLeisure === managerConst.CashPointLog.LOG_CASHPOINT_PROTO">
                             {{scope.row.betInfoId}}
                           </span>
                           <span v-if="scope.row.protoOrLeisure === managerConst.CashPointLog.LOG_CASHPOINT_LEISURE">
                             {{scope.row.betInfoId}}
                           </span>
                       </span>

                   </template>
               </el-table-column>
           </el-table>
       </div>
       <div class="pagePanel">
           <el-pagination
                   @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :current-page="pageNum"
                   :page-sizes="[20, 50, 100, 150, 200]"
                   :page-size="pageSize"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="pageTotal">
           </el-pagination>
       </div>
   </div>
</template>

<script>
    import managerConst from "../../common/administrator/managerConst";
    import {getLogCashPointList} from "../../network/manager/logCashPointRequest";

    export default {
        name: "UserInfoCashPointLog",
        data() {
            return {
                startDate: '',
                endDate: '',
                log:{display:managerConst.YES},
                pageNum: 1,
                pageSize: 20,
                orderBy: null,
                pageTotal: 0,
                logList:[],
                managerConst:managerConst,
                pickerOptions: {
                    shortcuts: [{
                        text: '오늘',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '어제',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '일주일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '15일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 14);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '30일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', date);
                        }
                    }]
                },
            }
        },
        props: {
            uid: {
                type: Number,
                default() {
                    return 0;
                }
            },

        },
        methods: {
            selectCashOrPoint(){
                this.log.type = null
                this.getLogList()
            },
            managerAddCashLogSearch (type) {
                this.log.userOrPartner = managerConst.CashPointLog.LOG_CASHPOINT_USER;
                this.log.cashOrPoint = null;
                this.log.type = type
                this.getLogList()
            },
            getLogList(){
                this.log.userId = this.uid;
                this.log.userOrPartner = managerConst.CashPointLog.LOG_CASHPOINT_USER;
                this.log.startDate = this.startDate;
                this.log.endDate = this.endDate;
                getLogCashPointList(this.log,this.pageNum,this.pageSize).then(res=>{
                    this.logList = res.data.data.result
                    this.logList.map(log=>{
                        if(log.type == managerConst.CashPointLog.LOG_CASHPOINT_TYPE_MINUS_CASH_EXCHANGE){
                            log.textColor = 'text-red'
                        }
                        if(log.type == managerConst.CashPointLog.LOG_CASHPOINT_TYPE_PLUS_CASH_RECHARGE){
                            log.textColor = 'text-blue'
                        }
                        if(log.cashOrPoint == managerConst.CashPointLog.LOG_CASHPOINT_POINT){
                            log.textColor = 'text-yewllo'
                        }
                    })
                    this.pageTotal = res.data.data.pageTotal
                })
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getLogList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getLogList();
            },
            setStartDate() {
                this.startDate = this.$moment(this.startDate).format('yyyy-MM-DD');
            },
            setEndDate() {
                this.endDate = this.$moment(this.endDate).format('yyyy-MM-DD')
            },

        },
        created(){

        },
        watch: {
            uid: {
                handler(newVal) {
                    if (newVal != null && newVal != 0) {
                        this.startDate = this.$moment().subtract(1, 'days').format('yyyy-MM-DD')
                        this.endDate = this.$moment().format('yyyy-MM-DD')
                        this.getLogList()
                    }

                },
                immediate: true,
                deep: false
            },
        }
    }
</script>

<style scoped>

</style>